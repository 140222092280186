// extracted by mini-css-extract-plugin
export var profile = "profile-module--profile--Nqf04";
export var profileTitle = "profile-module--profile-title--kLA57";
export var showProfileTitle = "profile-module--show-profile-title--wQxGD";
export var profilePicLink = "profile-module--profile-pic-link--+XhAe";
export var profilePic = "profile-module--profile-pic--5NtGR";
export var pic = "profile-module--pic--s3g+L";
export var showPic = "profile-module--show-pic--OQpj4";
export var profileLink = "profile-module--profile-link--zr55g";
export var link = "profile-module--link--Y+gQt";
export var showLink = "profile-module--show-link--ZQuWv";
export var profileBody = "profile-module--profile-body--t696U";
export var showProfileBody = "profile-module--show-profile-body--fj5SJ";