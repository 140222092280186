import React,{useEffect} from 'react'
import Gap from './gap'
import Button from './button'
import {profile,profileTitle,profilePicLink,profilePic,profileLink,pic,link,profileBody,showProfileTitle,showPic,showLink,showProfileBody} from './profile.module.css'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import { setShowProfile } from '../state/app'

const Profile = ({isShowProfile,dispatch}) => {

    useEffect(()=>{
        dispatch(setShowProfile(true))
    },[]);

    return (
        <div className={profile}>
                <h1 id="profile-title" className={(isShowProfile)?`${profileTitle} ${showProfileTitle}`: `${profileTitle}`} >Fahreza Isnanto</h1>
                <Gap height={30}/>
                <div className={profilePicLink}>
                    <div className={profilePic}>
                        <Link to="/">
                            <img className={(isShowProfile)?`${pic} ${showPic}`: `${pic}`} src='/profile_pic.png' alt='reza-img'/>
                        </Link>
                    </div>
                    <div className={profileLink}>
                        <a href='https://github.com/FahrezaIsnanto/' target="__blank">
                            <img className={(isShowProfile)?`${link} ${showLink}`: `${link}`} src='/ic_github.png' alt='ic-github'/>
                        </a>
                        <a href='https://www.instagram.com/fahrezaisnanto/' target="__blank">
                            <img className={(isShowProfile)?`${link} ${showLink}`: `${link}`} src='/ic_instagram.png' alt='ic-instagram'/>
                        </a>
                        <a href='https://id.linkedin.com/in/fahreza-isnanto-3192b0213' target="__blank">
                            <img className={(isShowProfile)?`${link} ${showLink}`: `${link}`} src='/ic_linkedin.png' alt='ic-linkedin'/>
                        </a>
                        <a href='https://www.youtube.com/channel/UCC5GkEGAsTIlqu4TXA8KxPA' target="__blank">
                            <img className={(isShowProfile)?`${link} ${showLink}`: `${link}`} src='/ic_youtube.png' alt='ic-youtube'/>
                        </a>
                    </div>
                </div>
                <Gap height={30}/>
                <p className={(isShowProfile)?`${profileBody} ${showProfileBody}`: `${profileBody}`} >A Full Stack Web Developer having an experience of building web and mobile applications with PHP / Javascript / ReactJS / GatsbyJS / Flutter.  Currently work at CV Destinasi Computindo, Semarang, Indonesia</p>
                <Gap height={30}/>
                <Button/>
            </div>
    )
}

export default connect(state => ({
    isShowProfile: state.app.isShowProfile
}),null)(Profile)
