import React from 'react'
import {button} from './button.module.css'

const Button = () => {
    return (
        <div className={button}>
            <a href='mailto:fahezaisnanto@gmail.com' target="__blank">Contact Me</a>
        </div>
    )
}

export default Button
